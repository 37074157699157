import type { UI } from '@/v2/types';
import styled from '@emotion/styled';
import { Box, Flex } from '@qga/roo-ui/components';
import { SanityImage } from '@experiences-ui/shared/components';
import ErrorBoundary from '@/libs/v2/components/ErrorBoundary';
import { dataLayer } from '@/v2/utils/dataLayer';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';

// Legacy
import Heading from '@/v2/../components/Heading';
import Link from '@/v2/../components/Link';
import useBreakpoints, { isScreen } from '@/libs/v2/hooks/useBreakpoints';

const StyledLink = styled(Link.Text)`
  width: 100%;
`;

interface Props extends UI.HomeDestination {
  index: number;
}

const DestinationCard = ({ image, name, properties, title, index }: Props) => {
  const shouldUseNewEvents = useGA4Events();
  const propertyCount = (properties && properties.length) ?? 0;

  // On desktop, always render a 296x120 image. On mobile, SanityImage (using next/image) will create
  // a srcset and load an image closest to the users 100vw, (as on mobile, this image is 100%).
  const breakpoints = useBreakpoints();
  const isMobile = isScreen(breakpoints)('xxs', 'xs', 'sm');
  const imageProps = !isMobile
    ? {
        width: 296,
        height: 120,
        layout: 'intrinsic',
        transformation: {
          w: 296,
          h: 120,
          fit: 'min',
        },
      }
    : undefined;

  return (
    <ErrorBoundary>
      <Box
        bg="background.card"
        boxShadow="light"
        onClick={() => {
          if (shouldUseNewEvents) {
            dataLayer.tileClickEvent({
              groupName: title,
              itemName: `${title} Holidays`,
              itemText: propertyCount
                ? `VIEW ${propertyCount} HOLIDAYS`
                : 'VIEW HOLIDAYS',
              index,
              url: `/${name}`,
            });
          }
        }}
      >
        <StyledLink
          as={`/destinations/${name}`}
          data-testid="LINK"
          external={false}
          href={`/destinations/${name}`}
          shallow={false}
        >
          <Box height={'120px'} position={'relative'}>
            <SanityImage
              data-testid="SANITY_DESTINATION_IMAGE"
              image={image}
              alt={title}
              objectFit="cover"
              {...imageProps}
            />
          </Box>
          <Flex flexDirection="column" p="4" height={[null, null, '122px']}>
            <Box mb="2">
              <Heading.h4>{title}</Heading.h4>
            </Box>
          </Flex>
        </StyledLink>
      </Box>
    </ErrorBoundary>
  );
};

export default DestinationCard;
