import React from 'react';
import { isEmpty } from 'ramda';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box, Flex } from '@qga/roo-ui/components';
import { DEALS_SLUG } from '@/constants';

import Heading from '@/components/Heading';
import Text from '@/components/Text';
import Button from '@/components/Button';
import { SanityImage } from '@experiences-ui/shared/components';
import Link from '@/components/Link';
import Campaign from '@/propTypes/Campaign';
import showPropertyPrice from '@/utils/campaigns/showPropertyPrice';
import getCheapestProperty from './utils';
import PricePerTraveller from '@/components/PricePerTraveller';
import isCampaignHidePerPersonPrice from '@/utils/isCampaignHidePerPersonPrice';

const StyledLink = styled(Link.Text)`
  width: 100%;
  font-weight: normal;
`;

const From = ({ nights }) => {
  const flightsAndNight = `Flights + ${pluralize('night', nights, true)}`;

  return (
    <Flex mt={[4, null, null]} justifyContent="flex-end" alignItems="center">
      <Heading.h5 color="primary" fontSize="sm">
        {nights ? `${flightsAndNight}` : 'Flights + stay'}
      </Heading.h5>
    </Flex>
  );
};

From.propTypes = {
  nights: PropTypes.number,
};

From.defaultProps = {
  nights: 0,
};

const Teaser = React.memo(({ campaign }) => {
  if (!campaign || isEmpty(campaign)) return null;
  if (!campaign?.dealBanner?.headline) return null;

  const property = getCheapestProperty(campaign.tiles || []);
  const hasPropertyPrice = property && showPropertyPrice(property);
  const isPricePerAdult = campaign.slug.includes('kids-fly-free');
  const hidePerPersonPrice = isCampaignHidePerPersonPrice(campaign);

  const { perAdult, perTraveller, total } =
    property?.cheapestOrigin?.price || {};
  const travellers = property?.travellers;
  const campaignUrl =
    campaign.slug === DEALS_SLUG
      ? `/${DEALS_SLUG}`
      : `/${DEALS_SLUG}/${campaign.slug}`;

  return (
    <Box data-testid="CAMPAIGN-BANNER">
      <Flex mb="1">
        <StyledLink
          data-testid="CAMPAIGN-BANNER-LINK"
          as={campaignUrl}
          href={campaignUrl}
        >
          <Flex flexDirection={['column', null, 'row']} boxShadow="light">
            <Box
              height={['160px', null, '375px']}
              width={[null, null, '780px']}
              position={'relative'}
            >
              <SanityImage
                data-testid="SANITY_CAMPAIGN_IMAGE"
                image={campaign.image}
                alt={campaign.headline || 'campaign-banner'}
                objectFit="cover"
              />
            </Box>
            <Flex
              pt={[3, null, 5]}
              px={[3, null, 5]}
              pb={[3, null, null]}
              flexDirection="column"
              bg="background.card"
              width={['100%', null, 'calc(100% - 780px)']}
            >
              <Heading.h4>{campaign.dealBanner.headline}</Heading.h4>
              <Flex
                height="100%"
                flexDirection="column"
                justifyContent="space-between"
              >
                {campaign.dealBanner?.tagline && (
                  <Flex mr="2" flexDirection="column">
                    <Text mt={[null, null, 2]}>
                      {campaign.dealBanner.tagline}
                    </Text>
                  </Flex>
                )}
                <Flex flexDirection="column">
                  <From nights={property?.nights} />
                  {hasPropertyPrice && (
                    <>
                      {hidePerPersonPrice ? (
                        <PricePerTraveller
                          perTraveller={perTraveller}
                          total={total}
                          travellers={travellers}
                          hidePerPersonPrice={hidePerPersonPrice}
                        />
                      ) : (
                        <PricePerTraveller
                          perAdult={perAdult}
                          perTraveller={perTraveller}
                          priceForAdult={isPricePerAdult}
                          total={total}
                          travellers={travellers}
                        />
                      )}

                      {property?.cheapestOrigin?.origin?.name && (
                        <Text textStyle="small" textAlign="right" pt="1">
                          {`Departing ${property.cheapestOrigin.origin.name}`}
                        </Text>
                      )}
                    </>
                  )}
                  <Button
                    width="100%"
                    outline
                    mr={[null, null, 2]}
                    mb="2"
                    mt="4"
                  >
                    View deals
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </StyledLink>
      </Flex>
      {campaign.dealBanner?.termsConditions && (
        <Text hint fontSize="xxs" lineHeight="16px">
          {campaign.dealBanner.termsConditions}
        </Text>
      )}
    </Box>
  );
});

Teaser.propTypes = {
  campaign: PropTypes.oneOfType([PropTypes.shape(Campaign), PropTypes.object]),
};

Teaser.defaultProps = {
  campaign: {},
};

export default Teaser;
